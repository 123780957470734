import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";
// import { isEmpty } from "lodash";

const initialState = {
    loading: false,
    error: false,
    responce: false,
    pushData: {test: false},
};

const BASE_URL = process.env.REACT_APP_API_KEY;

const getAuthData = async () => {
    let token = await Cookies.get("jwt");
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return config;
};

export const PushRequest = createAsyncThunk(
    'marketing/PushRequest', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.post(BASE_URL + `/api/v1/marketing/sendpush`, data, config);
            dispatch(setResponce(res.data));
        }   catch (error) {
            dispatch(setError(error.response.data.message));
            dispatch(clearPushData());
        }  
    }
); 

export const MarketingSlice = createSlice({
    name: 'marketing',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setResponce: (state, action) => {
            state.responce = action.payload;
        },
        setPushData: (state, action) => {
            state.pushData = { ...state.pushData, [action.payload.key]: action.payload.val };
        },
        clearPushData: (state, action) => {
            state.pushData = {test: false};
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => { state.loading = false; }
            );
    }
});

export const { setError, setPushData, setResponce, clearPushData} = MarketingSlice.actions

export default MarketingSlice.reducer