import React, { useEffect, useState, useRef, forwardRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { isEmpty } from 'lodash';

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import { getUserStat } from '../../features/AdminSlice';

registerLocale('ru', ru);
moment().locale('ru');

// Вынесенные компоненты для упрощения основного кода
const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className='btn btn-grd-warning text-white border-r-none border-media sm-btn'
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

const DiapasonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    style={{ borderLeft: 'none' }}
    className='btn btn-outline-warning border-r-none'
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

const DiapasonInputEnd = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className='btn btn-outline-warning btn-end'
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

// Таблица заказов вынесена в отдельный компонент
const OrdersTable = React.memo(({ orders, getColor, getStatus }) => {
  if (!orders || orders.length === 0) return null;
  
  return (
    <div className='table-responsive mt-5'>
      <table className="table align-middle">
        <thead>
          <tr>
            <th style={{ width: '13%' }}>ID</th>
            <th style={{ width: '25%' }}>Заведение</th>
            <th style={{ width: '12%' }}>Сумма</th>
            <th style={{ width: '10%' }}>Тип</th>
            <th style={{ width: '10%' }}>Выкупил</th>
            <th style={{ width: '10%' }}>Сумма выкупа</th>
            <th style={{ width: '20%' }}>Статус</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>
                <div className="d-flex align-items-center gap-3">
                  <p className="mb-0">{item.store}</p>
                </div>
              </td>
              <td>{item.total} руб.</td>
              <td>
                {item.pay_type === 'cash' ? (
                  <span className="">Наличные</span>
                ) : (
                  <span className="">Безналичные</span>
                )}
              </td>
							<td>
                {item.purchaser === 'courier' ? (
                  <span className="fw-bold">Курьер 🚗</span>
                ) : (
                  <span className="text-danger">Гонец 📦</span>
                )}
              </td>
							<td>{item.purchase_amount || 0} руб.</td>
              <td>
                <span className={`badge bg-grd-${getColor(item.status)}`} style={{ fontSize: '14px' }}>
                  {getStatus(item.status)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

// Основной компонент (переименован с Shop на UserDetails)
export default function UserDetails() {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { id, username } = useParams();
  const isFirstRender = useRef(true);
  
  const [activeDate, setActiveDate] = useState('today');
  const [openDatePicker, setOpenDatePicker] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [errorDiapason, setErrorDiapason] = useState(false);

  // Мемоизированные функции для улучшения производительности
  const getColor = useCallback((status) => {
    switch (status) {
      case "CREATED": return 'info';
      case "PENDING": return 'warning';
      case "SUCCESS": return 'success';
      case "CANCELED": return 'danger';
      case "MANUAL": return 'primary';
      default: return 'info';
    }
  }, []);

  const getStatus = useCallback((status) => {
    switch (status) {
      case "CREATED": return "Новый";
      case "PENDING": return "Принят курьером";
      case "SUCCESS": return "Завершен";
      case "CANCELED": return "Заказ отменен";
      case "MANUAL": return "Ручной чек";
      default: return "Неизвестно";
    }
  }, []);

  // Оптимизированный useEffect для избежания двойных запросов
  useEffect(() => {
    if (isFirstRender.current) {
      dispatch(getUserStat({
        'start': moment(startDate).format('YYYY-MM-DD'),
        'userId': id
      }));
      isFirstRender.current = false;
    }
  }, [dispatch, id, startDate]);

  // Обработчики событий с мемоизацией
  const checkStartDate = useCallback((date) => {
    if (moment(date).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')) {
      setErrorDiapason(true);
    } else {
      setErrorDiapason(false);
    }
    setStartDate(date);
  }, [endDate]);

  const checkEndDate = useCallback((date) => {
    if (moment(startDate).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD')) {
      setErrorDiapason(true);
    } else {
      setErrorDiapason(false);
    }
    setEndDate(date);
  }, [startDate]);

  const sendRequest = useCallback(() => {
    if (activeDate === 'endDate') {
      dispatch(getUserStat({
        'start': moment(startDate).format('YYYY-MM-DD'),
        'end': moment(endDate).format('YYYY-MM-DD'),
        'userId': id
      }));
    } else {
      dispatch(getUserStat({
        'start': moment(startDate).format('YYYY-MM-DD'),
        'userId': id
      }));
    }
  }, [activeDate, dispatch, endDate, id, startDate]);

  // Вычисляемое значение для заголовка периода
  const periodTitle = useMemo(() => {
    if (activeDate === 'endDate') {
      return `Статистика за период от ${moment(startDate).format('DD.MM.YYYY')} до ${moment(endDate).format('DD.MM.YYYY')}`;
    } 
    return `Статистика за ${activeDate === 'today' ? 'сегодня' : moment(startDate).format('DD.MM.YYYY')}`;
  }, [activeDate, startDate, endDate]);

  // Отрисовка компонента с разделением на более мелкие части
  return (
    <>
      <Sidebar />
      {errorDiapason && activeDate === 'endDate' &&
        <Alert
          title='Выберите правильный диапазон!'
          text='Стартовая дата должна быть меньше последней!'
        />
      }
      <main className="main-wrapper">
        <div className="main-content">
          {/* Breadcrumb */}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
              <Link className='' to="/user">
                <button type="button" className="btn btn-sm btn-outline-secondary no-hover px-4 d-flex gap-2">
                  <i className="material-icons-outlined">arrow_back</i>
                  Назад
                </button>
              </Link>
            </div>
          </div>

          {admin.loading ? (
            <div className='col-12 text-center mt-5'>
              <Loader />
            </div>
          ) : (
            <div className="row">
              {/* Карточка с основной информацией */}
              <div className="col-xxl-12 d-flex align-items-stretch">
                <div className="card w-100 overflow-hidden rounded-4">
                  <div className="card-body position-relative p-4">
                    <div className="row">
                      <div className="col-12 col-sm-12">
                        <div className="d-flex align-items-center gap-3 mb-5">
                          <div className="">
                            <p className="mb-0 fw-semibold">Статистика по курьеру</p>
                            <h1 className="fw-semibold mb-0">{username}</h1>
                          </div>
                        </div>
                        
                        {admin.userStat && (
                          <>
                            <div className="d-block d-md-flex align-items-center gap-4">
                              {/* Статистика */}
                              <div className="mb-4 mb-md-0">
                                <div className="info-list-item d-flex align-items-center gap-3">
                                  <img src={require('../../img/icons/clipboard.png')} className='' width={20} alt="icon" />
                                  <h4 className="mb-1 fw-semibold d-flex align-content-center text-info">
                                    {admin.userStat.totals.orders_count} шт.
                                  </h4>
                                </div>
                                <h6 className="mb-0 media-fs-17">Вcего заказов</h6>
                              </div>
                              
                              <div className="vr d-none d-md-block"></div>
                              
                              <div className="mb-4 mb-md-0">
                                <div className="info-list-item d-flex align-items-center gap-3">
                                  <img src={require('../../img/icons/money.png')} className='' width={20} alt="icon" />
                                  <h4 className="mb-1 fw-semibold d-flex align-content-center text-success">
                                    {admin.userStat.totals.total} ₽
                                  </h4>
                                </div>
                                <h6 className="mb-0 media-fs-17">Сумма заказов</h6>
                              </div>
                              
                              <div className="vr d-none d-md-block"></div>
                              
                              <div className="mb-4 mb-md-0">
                                <div className="info-list-item d-flex align-items-center gap-3">
                                  <img src={require('../../img/icons/credit-card.png')} className='' width={20} alt="icon" />
                                  <h4 className="mb-1 fw-semibold d-flex align-content-center text-danger">
                                    {admin.userStat.totals.card_pay} ₽
                                  </h4>
                                </div>
                                <h6 className="mb-0 media-fs-17">Оплачено картой</h6>
                              </div>
                              
                              <div className="vr d-none d-md-block"></div>
                              
                              <div className="mb-4 mb-md-0">
                                <div className="info-list-item d-flex align-items-center gap-3">
                                  <img src={require('../../img/icons/delivery.png')} className='' width={20} alt="icon" />
                                  <h4 className="mb-1 fw-semibold d-flex align-content-center text-primary">
                                    {admin.userStat.totals.cash_pay} ₽
                                  </h4>
                                </div>
                                <h6 className="mb-0 media-fs-17">Оплачено кешем</h6>
                              </div>
                              
                              <div className="vr d-none d-md-block"></div>
                              
                              <div className="mb-4 mb-md-0">
                                <div className="info-list-item d-flex align-items-center gap-3">
                                  <img src={require('../../img/icons/wallet.png')} className='' width={20} alt="icon" />
                                  <h4 className="mb-1 fw-semibold d-flex align-content-center text-warning">
                                    {admin.userStat.totals.courier_buy} ₽
                                  </h4>
                                </div>
                                <h6 className="mb-0 media-fs-17">Выкуплено курьером на сумму</h6>
                              </div>
                              
                              <div className="vr d-none d-md-block"></div>
                              
                              <div className="mb-4 mb-md-0">
                                <div className="info-list-item d-flex align-items-center gap-3">
                                  <img src={require('../../img/icons/business-and-finance.png')} className='' width={30} alt="icon" />
                                  <h2 className="mb-1 fw-semibold d-flex align-content-center">
                                    {admin.userStat.totals.courierSalary} ₽
                                  </h2>
                                </div>
                                <h6 className="mb-0 media-fs-17">ЗП курьера</h6>
                              </div>
                            </div>
                            
                            {/* Предупреждение */}
                            <div className="d-flex align-items-center alert alert-warning mt-4">
															<div className="font-35 text-warning d-none d-md-block">
																<span className="material-icons-outlined fs-2">info</span>
															</div>
															<div className="ms-3">
																<h6 className="mb-0 text-warning media-fs-14">
																	Суммы выше - указаны по заказам со статусами – «Завершен» и «Ручной чек».
																</h6>
																<div className="media-fs-12 mt-2">
																	Заказы с другими статусами («Новый», «Принят курьером», «Заказ отменен») в окончательные суммы не входят!
																</div>
															</div>
														</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Карточка с таблицей заказов */}
              <div className="col-12 d-flex align-items-stretch">
                <div className="card w-100 rounded-4">
                  <div className="card-body">
                    <div className="d-flex align-items-start justify-content-between m-md-3 m-1 w-100">
                      <div className="row w-100">
                        {/* Заголовок периода */}
                        <div className='col-12 col-md-6'>
                          <h5 className="mb-0 mt-2">{periodTitle}</h5>
                        </div>
                        
                        {/* Селектор дат */}
                        <div className='col-12 col-md-6 text-end pe-md-5 pe-0 mt-3 mt-md-0'>
                          <div className="btn-group w-100" role="group">
                            <button
                              type="button"
                              className={`btn d-none d-md-block ${activeDate === 'today' ? 'btn-grd btn-grd-warning' : 'btn-outline-warning'}`}
                              onClick={() => {
                                setActiveDate('today');
                                setStartDate(new Date());
                                setOpenDatePicker(true);
                              }}
                            >
                              Сегодня
                            </button>
                            
                            {activeDate !== 'date' ? (
                              <button
                                type="button"
                                className='btn btn-outline-warning border-media sm-btn'
                                onClick={() => setActiveDate('date')}
                              >
                                Выбрать дату
                              </button>
                            ) : (
                              openDatePicker ? (
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                    setOpenDatePicker(false);
                                  }}
                                  customInput={<CustomInput />}
                                  locale="ru"
                                  open={true}
                                  dateFormat="dd/MM/yyyy"
                                  popperClassName="date-picker"
                                />
                              ) : (
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  customInput={<CustomInput />}
                                  locale="ru"
                                  dateFormat="dd/MM/yyyy"
                                  popperClassName="date-picker"
                                />
                              )
                            )}
                            
                            <button
                              type="button"
                              className={`btn sm-btn ${activeDate === 'endDate' ? 'd-none' : 'btn-outline-warning btn-end'}`}
                              onClick={() => setActiveDate('endDate')}
                            >
                              Выбрать диапазон
                            </button>
                            
                            {activeDate === 'endDate' && (
                              <div className='datepicker-group d-flex'>
                                <div>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                      checkStartDate(date);
                                      setOpenDatePicker(true);
                                    }}
                                    customInput={<DiapasonInput />}
                                    locale="ru"
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </div>
                                <div>
                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                      checkEndDate(date);
                                      setOpenDatePicker(true);
                                    }}
                                    customInput={<DiapasonInputEnd />}
                                    locale="ru"
                                    dateFormat="dd/MM/yyyy"
                                    popperClassName="diapason-picker"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          
                          <button
                            type="button"
                            className="btn btn-grd btn-grd-success px-md-3 px-0 ms-auto mt-3"
                            onClick={sendRequest}
                          >
                            <i
                              className="material-icons-outlined me-1"
                              style={{
                                position: 'relative',
                                top: '4px',
                                fontSize: '18px'
                              }}
                            >
                              refresh
                            </i>
                            Обновить отчет
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    {admin.loading ? (
                      <div className='col-12 text-center mt-5'>
                        <Loader />
                      </div>
                    ) : !isEmpty(admin.userStat?.orders) ? (
                      <OrdersTable 
                        orders={admin.userStat.orders} 
                        getColor={getColor}
                        getStatus={getStatus}
                      />
                    ) : (
                      <div className='row'>
                        <div className='col-12 text-center justify-content-center my-5'>
                          <button className="btn btn-outline-warning no-hover text-warning p-3" type="button" disabled="">
                            {activeDate === 'endDate' ? (
                              <strong>
                                У курьера {username} нету заказов
                                <br />
                                за период от {moment(startDate).format('DD.MM.YYYY')} до {moment(endDate).format('DD.MM.YYYY')}
                              </strong>
                            ) : (
                              <strong>
                                У курьера {username} нету заказов за {moment(startDate).format('DD.MM.YYYY')}
                              </strong>
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
}