import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";

const initialState = {
    loading: false,
    responce: false,
    orders: false,
};

const BASE_URL = process.env.REACT_APP_API_KEY;

const getAuthData = async () => {
    let token = await Cookies.get("jwt");
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return config;
};

export const getOrders = createAsyncThunk(
    'orders/getOrders', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            let res;
            if (!isEmpty(data)) {
                res = await axios.get(BASE_URL + `/api/v1/orders?date=${data.date}`, config)
            } else {
                res = await axios.get(BASE_URL + `/api/v1/orders`, config)
            }
            dispatch(setOrders(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
); 

export const deleteOrder = createAsyncThunk(
    'orders/deleteOrder', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.post(BASE_URL + `/api/v1/order/remove`, data, config)
            dispatch(setResponce(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
); 

export const OrdersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setResponce: (state, action) => {
            state.responce = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => { state.loading = false; }
            );
    }
});

export const { setOrders, setResponce } = OrdersSlice.actions

export default OrdersSlice.reducer