import React, { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { isEmpty } from 'lodash';
import { evaluate } from 'mathjs'

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';

import { getShopStat } from '../../features/AdminSlice';
import { Link } from 'react-router-dom';

registerLocale('ru', ru);
moment().locale('ru');

export default function Shop() {

    const dispatch = useDispatch();
    const admin = useSelector((state) => state.admin);
    const store_name = useParams();

    const [activeDate, setActiveDate] = useState('today');
    const [openDatePicker, setOpenDatePicker] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [shopName, setShopName] = useState(false);
    const [percentage, setPercentage] = useState(false);
    const [errorDiapason, setErrorDiapason] = useState(false);

    useEffect(() => {
        dispatch(getShopStat({
            'date': moment(startDate).format('YYYY-MM-DD'), 
            'store': store_name
        }));
        setShopName(store_name.store_name);
        setPercentage(store_name.percentage);
        console.log('store_name', store_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button 
            type="button" 
            className='btn btn-grd-warning text-white border-r-none border-media sm-btn' 
            onClick={onClick}
            ref={ref}
        >
            {value}
        </button>
    ));

    const DiapasonInput = forwardRef(({ value, onClick }, ref) => (
        <button 
            type="button" 
            style={{borderLeft: 'none'}}
            className='btn btn-outline-warning border-r-none' 
            onClick={onClick}
            ref={ref}
        >
            {value}
        </button>
    ));

    const DiapasonInputEnd = forwardRef(({ value, onClick }, ref) => (
        <button 
            type="button" 
            className='btn btn-outline-warning btn-end'
            onClick={onClick}
            ref={ref}
        >
            {value}
        </button>
    ));

    const checkStartDate = (date) => {
        if (moment(date).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')) {
            setErrorDiapason(true);
        } else {
            setErrorDiapason(false);
        }
        setStartDate(date); 
    };

    const checkEndDate = (date) => {
        if (moment(startDate).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD')) {
            setErrorDiapason(true);
        } else {
            setErrorDiapason(false);
        }
        setEndDate(date); 
    };

    const sendRequest = () => {
        if (activeDate === 'endDate') {
            dispatch(getShopStat({
                'date': moment(startDate).format('YYYY-MM-DD'), 
                'endDate': moment(endDate).format('YYYY-MM-DD'), 
                'store': store_name
            }));
        } else {
            dispatch(getShopStat({
                'date': moment(startDate).format('YYYY-MM-DD'), 
                'store': store_name
            }));
        }
    };
    
    const getColor = (status) => {
        switch (status) {
          case "CREATED":
            return 'info'
          case "PENDING":
            return 'warning'
          case "SUCCESS":
            return 'success'    
          case "CANCELED":
            return 'danger'
          case "MANUAL":
            return 'primary'
					default:
						return 'info'
        }
    };

    const getStatus = (status) => {
        switch (status) {
          case "CREATED":
            return "Новый"
          case "PENDING":
            return "Принят курьером"
          case "SUCCESS":
            return "Завершен"
          case "CANCELED":
            return "Заказ отменен"
          case "MANUAL":
            return "Ручной чек"
					default:
						return "Неизвестно"
        }

    };

    // FIXME: Не работает
    const GetPercentage = () => {
        if (percentage !== 0 && !isEmpty(admin?.shopStat?.totals)) { 
            const profit_orders = evaluate(`(${admin.shopStat.totals.clear_total || 0} * ${percentage || 0}) / 100`) ;
            const profit = evaluate(`${profit_orders || 0} + ${admin.shopStat.totals.delivery_total || 0} + ${admin.shopStat.totals.fee_total || 0}`);
            return profit.toFixed(2);
        } else {
            return '0';
        }
    }

    return (
        <>
            <Sidebar/>
            {errorDiapason === true && activeDate === 'endDate' &&
                <Alert
                    title='Выберите правильный диапазон!'
                    text='Стартовая дата должна быть меньше последней!'
                />
            }
            <main className="main-wrapper">
                <div className="main-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="ps-3">
                            <Link className='' to={`/shops`}>
                                <button type="button" className="btn btn-sm btn-outline-secondary no-hover px-4 d-flex gap-2">
                                    <i className="material-icons-outlined">arrow_back</i>
                                    Назад
                                </button>
                            </Link>
                        </div>
                    </div>
                    {admin.loading === true ? (
                        <div className='col-12 text-center mt-5'>
                            <Loader/>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-xxl-12 d-flex align-items-stretch">
                                <div className="card w-100 overflow-hidden rounded-4">
                                    <div className="card-body position-relative p-4">
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <div className="d-flex align-items-center gap-3 mb-5">
                                                    <div className="">
                                                        <p className="mb-0 fw-semibold">Статистика по заведению</p>
                                                        <h1 className="fw-semibold mb-0 mb-0">{shopName && shopName}</h1>
                                                        {percentage && (
                                                            <h6 className="mt-2 media-fs-17 text-muted">Комиссия выкупа: {percentage}%</h6>
                                                        )}
                                                    </div>
                                                </div>
                                                {admin.shopStat && 
                                                    <>
                                                        <div className="d-block d-md-flex align-items-center gap-4">
                                                            <div className="mb-4 mb-md-0">
                                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                                    <img src={require('../../img/icons/clipboard.png')} className='' width={20} alt="icon"/>
                                                                    <h4 className="mb-1 fw-semibold d-flex align-content-center text-info">
                                                                        {admin.shopStat.orders.length} шт.
                                                                    </h4>
                                                                </div>
                                                                <h6 className="mb-0 media-fs-17">
                                                                    Вcего заказов
                                                                </h6>
                                                            </div>
                                                            <div className="vr d-none d-md-block"></div>
                                                            <div className="mb-4 mb-md-0">
                                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                                    <img src={require('../../img/icons/money.png')} className='' width={20} alt="icon"/>
                                                                    <h4 className="mb-1 fw-semibold d-flex align-content-center text-success">
                                                                        {admin.shopStat.totals.total_cash} ₽
                                                                    </h4>
                                                                </div>
                                                                <h6 className="mb-0 media-fs-17">
                                                                    Вcего наличкой
                                                                </h6>
                                                            </div>
                                                            <div className="vr d-none d-md-block"></div>
                                                            <div className="mb-4 mb-md-0">
                                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                                    <img src={require('../../img/icons/credit-card.png')} className='' width={20} alt="icon"/>
                                                                    <h4 className="mb-1 fw-semibold d-flex align-content-center text-danger">
                                                                        {admin.shopStat.totals.total_card} ₽
                                                                    </h4>
                                                                </div>
                                                                <h6 className="mb-0 media-fs-17">
                                                                    Вcего безналичкой 
                                                                </h6>
                                                            </div>
                                                            <div className="vr d-none d-md-block"></div>
                                                            <div className="mb-4 mb-md-0">
                                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                                    <img src={require('../../img/icons/delivery.png')} className='' width={20} alt="icon"/>
                                                                    <h4 className="mb-1 fw-semibold d-flex align-content-center text-primary">
                                                                        {evaluate(`${admin.shopStat.totals.delivery_total || 0} + ${admin.shopStat.totals.fee_total || 0}`)} ₽
                                                                    </h4>
                                                                </div>
                                                                <h6 className="mb-0 media-fs-17">
                                                                    Доставоки + сбор
                                                                </h6>
                                                            </div>
                                                            <div className="vr d-none d-md-block"></div>
                                                            <div className="mb-4 mb-md-0">
                                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                                    <img src={require('../../img/icons/wallet.png')} className='' width={20} alt="icon"/>
                                                                    <h4 className="mb-1 fw-semibold d-flex align-content-center text-warning">
                                                                        {admin.shopStat.totals.clear_total} ₽
                                                                    </h4>
                                                                </div>
                                                                <h6 className="mb-0 media-fs-17">
                                                                    Сумма тело заказа
                                                                </h6>
                                                            </div>
                                                            <div className="vr d-none d-md-block"></div>
                                                            {percentage === "0" ? (
                                                                <h6>‼️ % Не прописан </h6>
                                                            ) : (
                                                                <div className="mb-4 mb-md-0">
                                                                    <div className="info-list-item d-flex align-items-center gap-3">
                                                                        <img src={require('../../img/icons/business-and-finance.png')} className='' width={30} alt="icon"/>
                                                                        <h2 className="mb-1 fw-semibold d-flex align-content-center">
                                                                            {GetPercentage()} ₽
                                                                        </h2>
                                                                    </div>
                                                                    <h6 className="mb-0 media-fs-17">
                                                                        Чистая прибыль
                                                                    </h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="alert alert-border-warning alert-dismissible fade show me-md-5 me-0 mb-0 mt-5 pe-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="font-35 text-warning d-none d-md-block">
                                                                    <span className="material-icons-outlined fs-2">info</span>
                                                                </div>
                                                                <div className="ms-3">
                                                                    <h6 className="mb-0 text-warning media-fs-14">Суммы выше - указаны по заказам со статусами – «Завершен» и «Ручной чек».</h6>
                                                                    <div className="media-fs-12 mt-2">Заказы с другими статусами («Новый», «Принят курьером», «Заказ отменен») в окончательные суммы не входят!</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <div className="welcome-back-img pt-4">
                                                    <img src="assets/images/gallery/welcome-back-3.png" height="180" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex align-items-stretch">
                                <div className="card w-100 rounded-4">
                                    <div className="card-body">
                                        <div className="d-flex align-items-start justify-content-between m-md-3 m-1 w-100">
                                            <div className="row w-100">
                                                <div className='col-12 col-md-6'>
                                                    <h5 className="mb-0 mt-2">
                                                        {activeDate === 'endDate' ? (
                                                            `Статистика за период от ${moment(startDate).format('DD.MM.YYYY')} до ${moment(endDate).format('DD.MM.YYYY')}`
                                                        ) : (
                                                            `Статистика за ${activeDate === 'today' ? 'сегодня' : `${moment(startDate).format('DD.MM.YYYY')}`}`
                                                        )}
                                                    </h5>
                                                </div>
                                                <div className='col-12 col-md-6 text-end pe-md-5 pe-0 mt-3 mt-md-0'>
                                                    <div className="btn-group w-100" role="group" >
                                                        <button 
                                                            type="button" 
                                                            className={`btn d-none d-md-block ${activeDate === 'today' ? 'btn-grd btn-grd-warning' : 'btn-outline-warning'}`}
                                                            onClick={() => {
                                                                setActiveDate('today');
                                                                setStartDate(new Date());
                                                                setOpenDatePicker(true);
                                                            }}
                                                        >
                                                            Сегодня
                                                        </button>
                                                        {activeDate !== 'date' ? (
                                                            <button 
                                                                type="button" 
                                                                className='btn btn-outline-warning border-media sm-btn'
                                                                onClick={() => setActiveDate('date')}
                                                            >
                                                                Выбрать дату
                                                            </button>
                                                        ) : (
                                                            openDatePicker ? (
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => {
                                                                        setStartDate(date); 
                                                                        setOpenDatePicker(false); 
                                                                    }}
                                                                    customInput={<CustomInput />}
                                                                    locale="ru"
                                                                    open={true}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    popperClassName="date-picker"
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    customInput={<CustomInput />}
                                                                    locale="ru"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    popperClassName="date-picker"
                                                                />
                                                            )
                                                        )}
                                                        <button 
                                                            type="button" 
                                                            className={`btn sm-btn ${activeDate === 'endDate' ? 'd-none' : 'btn-outline-warning btn-end'}`}
                                                            onClick={() => setActiveDate('endDate')}
                                                        >
                                                            Выбрать диапазон
                                                        </button>
                                                        {activeDate === 'endDate' && 
                                                            <div className='datepicker-group d-flex '>
                                                                <div>
                                                                    <DatePicker
                                                                        selected={startDate}
                                                                        onChange={(date) => {
                                                                            checkStartDate(date);
                                                                            setOpenDatePicker(true); 
                                                                        }}
                                                                        customInput={<DiapasonInput />}
                                                                        locale="ru"
                                                                        dateFormat="dd/MM/yyyy"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <DatePicker
                                                                        selected={endDate}
                                                                        onChange={(date) => {
                                                                            checkEndDate(date);
                                                                            setOpenDatePicker(true); 
                                                                        }}
                                                                        customInput={<DiapasonInputEnd />}
                                                                        locale="ru"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        popperClassName="diapason-picker"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-grd btn-grd-success px-md-3 px-0 ms-auto mt-3"
                                                        onClick={() => sendRequest()}
                                                    >
                                                        <i 
                                                            className="material-icons-outlined me-1"
                                                            style={{
                                                                position: 'relative',
                                                                top: '4px',
                                                                fontSize: '18px'
                                                            }}
                                                        >
                                                            refresh
                                                        </i>
                                                        Обновить статистику
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {admin.loading === true ? (
                                            <div className='col-12 text-center mt-5'>
                                                <Loader/>
                                            </div>
                                        ) : (
                                            !isEmpty(admin.shopStat.orders) ? (
                                                <div className='table-responsive mt-5'>
                                                    <table className="table align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th style={{width: '10%'}}>ID</th>
                                                                <th style={{width: '25%'}}>Заведение</th>
                                                                <th style={{width: '15%'}}>Суммы</th>
                                                                <th style={{width: 'auto'}}>Доставка</th>
                                                                <th style={{width: '10%'}}>Тип</th>
                                                                <th style={{width: '20%'}}>Статус</th>
                                                                <th>Дата</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {admin.shopStat.orders.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.id}</td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <p className="mb-0">{item.store}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p className='mb-0'>С клиента: {item.total} ₽</p>
                                                                        <p className='mb-0'>Тело заказа: {item.subtotal} ₽</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className='mb-0'>Доставка: {item.delivery_price} ₽</p>
                                                                        <p className='mb-0'>Сбор: {item.fee && item.fee !== '0' ? item.fee + ' ₽' : 'в доставке'}</p>
                                                                    </td>
                                                                    <td>
                                                                        {item.pay_type === 'cash' ? (
                                                                            <span className="text-success">Наличные</span>
                                                                        ) : (
                                                                            <span className="text-danger">Безналичные</span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge bg-grd-${getColor(item.status)}`} style={{fontSize: '14px'}}>
                                                                            {getStatus(item.status)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {moment(item.created_at).format('DD.MM.YYYY')}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <div className='row'>
                                                    <div className='col-12 text-center justify-content-center my-5'>
                                                        <button className="btn btn-outline-warning no-hover text-warning p-3" type="button" disabled=""> 
                                                            {activeDate === 'endDate' ? (
                                                                <strong>У заведения {shopName} нету заказов 
                                                                <br/>
                                                                за период от {moment(startDate).format('DD.MM.YYYY')} до {moment(endDate).format('DD.MM.YYYY')}</strong>
                                                            ) : (
                                                                <strong>У заведения {shopName} нету заказов за {moment(startDate).format('DD.MM.YYYY')}</strong>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}  
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            {/* <div className={`modal fade ${modalShow === true && 'show'}`} style={{display: `${modalShow === true ? 'block' : 'none'}`}} >
                <div className="modal-dialog modal-dialog-centered w-50" style={{maxWidth: 'none'}}>
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 py-2">
                            <h5 className="modal-title m-2 mt-3">
                                Отчет по заведению 
                                <strong className='text-danger'> {shopName} </strong>
                                {activeDate === 'endDate' ? (
                                    <span>за период от {moment(startDate).format('DD.MM.YYYY')} до {moment(endDate).format('DD.MM.YYYY')}</span>
                                ) : (
                                    <span>за {moment(startDate).format('DD.MM.YYYY')}</span>
                                )}
                            </h5>
                            <a href="#" className="primaery-menu-close" onClick={() => {setModalShow(false)}}>
                                <i className="material-icons-outlined">close</i>
                            </a>
                        </div>
                        <div className="modal-body p-md-4 p-2">
                            {admin.loading === true ? (
                                <div className='col-12 text-center mt-5'>
                                    <Loader/>
                                </div>
                            ) : (
                                !isEmpty(admin.shopStat.orders) ? (
                                    <>
                                        <div className='table-responsive'>
                                            <table className="table align-middle">
                                                <thead>
                                                    <tr>
                                                        <th style={{width: '10%'}}>ID</th>
                                                        <th style={{width: '25%'}}>Заведение</th>
                                                        <th style={{width: '15%'}}>Сумма</th>
                                                        <th style={{width: '20%'}}>Тип</th>
                                                        <th style={{width: '30%'}}>Статус</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {admin.shopStat.orders.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.id}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <p className="mb-0">{item.store}</p>
                                                                </div>
                                                            </td>
                                                            <td>{item.total} ₽</td>
                                                            <td>
                                                                {item.pay_type === 'cash' ? (
                                                                    <span className="text-success">Наличные</span>
                                                                ) : (
                                                                    <span className="text-danger">Безналичные</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <span className={`badge bg-grd-${getColor(item.status)}`} style={{fontSize: '14px'}}>
                                                                    {getStatus(item.status)}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="full-info mx-md-2 mx-4 mt-5">
                                            <div className="info-list d-flex flex-column gap-3">
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/clipboard.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего заказов:
                                                        <strong className='text-info'> {admin.shopStat.orders.length} шт.</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/money.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего наличкой: 
                                                        <strong className='text-success'> {admin.shopStat.totals.total_cash} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/credit-card.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего безналичкой: 
                                                        <strong className='text-danger'> {admin.shopStat.totals.total_card} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/credit-card.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Сумма доставок: 
                                                        <strong className='text-primary'> {admin.shopStat.totals.delivery_total} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/wallet.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего: 
                                                        <strong className='text-warning'> {admin.shopStat.totals.total} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="alert alert-border-warning alert-dismissible fade show me-md-5 me-0 mb-0 mt-3 pe-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="font-35 text-warning d-none d-md-block">
                                                            <span className="material-icons-outlined fs-2">info</span>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h6 className="mb-0 text-warning media-fs-14">Суммы выше - указаны по заказам со статусами – «Завершен» и «Ручной чек».</h6>
                                                            <div className="media-fs-12 mt-2">Заказы с другими статусами («Новый», «Принят курьером», «Заказ отменен») в окончательные суммы не входят!</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='row'>
                                        <div className='col-12 text-center justify-content-center my-5'>
                                            <button className="btn btn-outline-warning no-hover text-warning p-3" type="button" disabled=""> 
                                                {activeDate === 'endDate' ? (
                                                    <strong>У заведения {shopName} нету заказов 
                                                    <br/>
                                                    за период от {moment(startDate).format('DD.MM.YYYY')} до {moment(endDate).format('DD.MM.YYYY')}</strong>
                                                ) : (
                                                    <strong>У заведения {shopName} нету заказов за {moment(startDate).format('DD.MM.YYYY')}</strong>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}   
                        </div>
                        <div className="modal-footer border-top-0">
                            <button 
                                type="button" 
                                className="btn btn-grd-warning text-white" 
                                onClick={() => {setModalShow(false)}}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};  