import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { useParams } from 'react-router';

import Cookies from "js-cookie";

export default function Sidebar() {

    const [showMenu, setShowMenu] = useState(false);
    // const store_name = useParams();

    const renderActiveClass = (type) => {
        if (window.location.pathname === type) {
            return 'active';
        } else {
            return '';
        }
    };

    const logout = () => {
        Cookies.remove("jwt");
        Cookies.remove("adminId");
        window.location.reload();
    };

    return (
        <>
            {/* mobile menu */}
                <header className="top-header mobile-menu">
                    <nav className="navbar navbar-expand align-items-center gap-4">
                        <div className="btn-toggle">
                            <i className="material-icons-outlined text-warning" onClick={() => setShowMenu(true)}>menu</i>
                        </div>
                        <div className="logo-icon ms-auto">
                            <Link to={'/'}>
                                <img src={require('../img/logo/logo_vector.png')} className="logo-img" width={100} alt="Logo Gonec" />
                            </Link>
                        </div>
                    </nav>
                </header>
            {/* web menu */}
                <aside className={`sidebar-wrapper ${showMenu ? "show" : 'not-show'}`} data-simplebar="init">
                    <div className="simplebar-wrapper" style={{margin: '0px'}}>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                                <div className="simplebar-content-wrapper" style={{height: '100%', overflow: 'hidden scroll'}}>
                                    <div className="simplebar-content" style={{padding: '0px'}}>
                                        <div className="sidebar-header">
                                            <div className="logo-icon">
                                                <Link to={'/'}>
                                                    <img src={require('../img/logo/logo_new.png')} className="logo-img" alt="Logo Gonec" />
                                                </Link>
                                            </div>
                                            <div className="btn-toggle ms-auto mobile-menu">
                                                <i className="material-icons-outlined text-warning" onClick={() => setShowMenu(false)}>close</i>
                                            </div>
                                        </div>
                                        <div className="sidebar-nav mm-active">
                                            <ul className="metismenu mm-show">
                                                <li>
                                                    <Link to={'/'} className={`${renderActiveClass('/')}`}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">home</i>
                                                        </div>
                                                        <div className="menu-title">Главная</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'/orders'} className={`${renderActiveClass('/orders')}`}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">dashboard</i>
                                                        </div>
                                                        <div className="menu-title">Заказы</div>
                                                    </Link>
                                                </li>
                                                <li className="menu-label">Статистика</li>
                                                <li>
                                                    <Link to={'/shops'} className={`${renderActiveClass('/shops')}`}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">shopping_bag</i>
                                                        </div>
                                                        <div className="menu-title">По заведениям</div>
                                                    </Link>
                                                    {/* {store_name && 
                                                        <ul className="mm-collapse mm-show">
                                                            <li>
                                                                <a className='active border-none'>
                                                                    <i className="material-icons-outlined">arrow_right</i>
                                                                    Заведение
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    } */}
                                                    </li>
                                                <li>
                                                    <Link to={"/user"} className={`${renderActiveClass('/user')}`}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">person</i>
                                                        </div>
                                                        <div className="menu-title">По пользователям</div>
                                                    </Link>
                                                </li>
                                                <li className="menu-label">Карты</li>
                                                <li>
                                                    <Link to={'/map'} className={`${renderActiveClass('/map')}`}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">map</i>
                                                        </div>
                                                        <div className="menu-title">Online-Карта</div>
                                                    </Link>
                                                </li>
                                                <li className="menu-label">Маркетинг</li>
                                                <li>
                                                    <Link to={'/push'} className={`${renderActiveClass('/push')}`}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">notifications_none</i>
                                                        </div>
                                                        <div className="menu-title">Push-notification</div>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={'#'} onClick = {() => logout()}>
                                                        <div className="parent-icon">
                                                            <i className="material-icons-outlined">login</i>
                                                        </div>
                                                        <div className="menu-title">Выйти</div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
        </>
    );
};    