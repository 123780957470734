import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { isEmpty } from 'lodash';

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';

import { getOrders, deleteOrder } from '../../features/OrdersSlice';

registerLocale('ru', ru);
moment().locale('ru');

export default function OrdersPage() {

	const dispatch = useDispatch();
	const state = useSelector((state) => state.orders);

	const [activeDate, setActiveDate] = useState('today');
	const [openDatePicker, setOpenDatePicker] = useState(true);
	const [startDate, setStartDate] = useState(new Date());

	useEffect(() => {
		dispatch(getOrders());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state.responce.message === 'Ycnex.') {
			setTimeout(() => {
				dispatch(getOrders());
			}, "1000");
		}
	}, [dispatch, state.responce]);

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<button
			type="button"
			className='btn btn-grd-warning btn-end text-white'
			onClick={onClick}
			ref={ref}
		>
			{value}
		</button>
	));

	const getColor = (status) => {
		switch (status) {
			case "CREATED":
				return 'info'
			case "PENDING":
				return 'warning'
			case "SUCCESS":
				return 'success'
			case "CANCELED":
				return 'danger'
			case "MANUAL":
				return 'primary'
			default:
				return 'info'
		}
	};

	const getStatus = (status) => {
		switch (status) {
			case "CREATED":
				return "Новый"
			case "PENDING":
				return "Принят курьером"
			case "SUCCESS":
				return "Завершен"
			case "CANCELED":
				return "Заказ отменен"
			case "MANUAL":
				return "Ручной чек"
			default:
				return "Неизвестно"
		}

	};

	const confirmDelete = (id) => {
		if (window.confirm(`Вы уверены, что хотите удалить заказ №${id}?`)) {
			dispatch(deleteOrder({ 'order_id': `${id}` }));
		}
	};

	const getTodayOrders = () => {
		setActiveDate('today');
		setStartDate(new Date());
		setOpenDatePicker(true);
		dispatch(getOrders());
	};

	return (
		<>
			<Sidebar />
			<main className="main-wrapper">
				<div className="main-content">
					<div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
						<div className="ps-3">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb mb-0 p-0">
									<li className="breadcrumb-item active" aria-current="page">Заказы</li>
								</ol>
							</nav>
						</div>
					</div>
					{state.loading === true ? (
						<div className='col-12 text-center mt-5'>
							<Loader />
						</div>
					) : (
						<div className="row">
							<div className="col-12 d-flex align-items-stretch">
								<div className="card w-100 rounded-4">
									<div className="card-body">
										<div className="d-flex align-items-start justify-content-between m-3 mb-5 w-100">
											<div className="row w-100">
												<div className='col-12 col-md-6'>
													<h5 className="mb-0">
														Заказы за {activeDate === 'today' ? 'сегодня' : `${moment(startDate).format('DD.MM.YYYY')}`}
													</h5>
													<h6 className="mb-0 mt-2">
														Всего заказов:
														{state.orders ?
															<strong className='text-info'> {state.orders.length} шт.</strong>
															:
															<strong className='text-info'> 0 шт.</strong>
														}
													</h6>
												</div>
												<div className='col-12 col-md-6 text-end pe-5 mt-3 mt-md-0'>
													<div className="btn-group" role="group" >
														<button
															type="button"
															className={`btn ${activeDate === 'today' ? 'btn-grd btn-grd-warning' : 'btn-outline-warning'}`}
															onClick={() => getTodayOrders()}
														>
															Сегодня
														</button>
														{activeDate === 'today' ? (
															<button
																type="button"
																className={`btn ${activeDate === 'date' ? 'btn-grd btn-grd-warning' : 'btn-outline-warning'}`}
																onClick={() => setActiveDate('date')}
															>
																Выбрать дату
															</button>
														) : (
															openDatePicker ? (
																<DatePicker
																	selected={startDate}
																	onChange={(date) => {
																		setStartDate(date);
																		setOpenDatePicker(false);
																		dispatch(getOrders({ 'date': moment(date).format('YYYY-MM-DD') }));
																	}}
																	customInput={<CustomInput />}
																	locale="ru"
																	dateFormat="dd/MM/yyyy"
																	open={true}
																/>
															) : (
																<DatePicker
																	selected={startDate}
																	onChange={(date) => {
																		setStartDate(date);
																		dispatch(getOrders({ 'date': moment(date).format('YYYY-MM-DD') }));
																	}}
																	customInput={<CustomInput />}
																	locale="ru"
																	dateFormat="dd/MM/yyyy"
																/>
															)
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="table-responsive">
											{!isEmpty(state.orders) ? (
												<table className="table align-middle w-100" style={{ overflowX: 'scroll' }}>
													<thead>
														<tr>
															<th style={{ width: '7%' }}>ID</th>
															<th style={{ width: '11%' }}>Курьер</th>
															<th style={{ width: '14%' }}>Заведение</th>
															<th style={{ width: '8%' }}>Сумма</th>
															<th style={{ width: '11%' }}>Тип оплаты</th>
															<th style={{ width: '17%' }}>Статус</th>
															<th style={{ width: '8%' }}>Создан в</th>
															<th style={{ width: '15%' }}>Инфо</th>
															<th style={{ width: '10%' }}>Действия</th>
														</tr>
													</thead>
													<tbody>
														{state.orders.map((item, index) => (
															<tr key={index}>
																<td>{item.id}</td>
																<td>
																	{item.user_id === null ? (
																		"еще не назначен"
																	) : (
																		<a href={`https://t.me/${item.username}`} target='_blank' rel="noreferrer">@{item.username}</a>
																	)}
																</td>
																<td>
																	<div className="d-flex align-items-center gap-3">
																		<p className="mb-0">{item.store}</p>
																	</div>
																</td>
																<td>{item.total} ₽</td>
																<td>
																	{item.pay_type === 'cash' ? (
																		<span className="">Наличные 💵</span>
																	) : (
																		<span className="">Оплата картой 💳</span>
																	)}
																</td>
																<td>
																	<span className={`badge bg-grd-${getColor(item.status)}`} style={{ fontSize: '14px' }}>
																		{getStatus(item.status)}
																	</span>
																</td>
																<td>{moment(item.created_at).format('LT')}</td>
																<td>
																	<p className='mb-0'>Доставка: {item.delivery_price} ₽</p>
																	<p className='mb-0'>Выкупил: {item.purchaser === 'gonec' ? 'Гонец 📦' : 'Курьер 🚗'}</p>
																	<p className='mb-0'>Выкуп оператор: {item.cost_amount || 0} ₽</p>
																	{item.purchaser === 'courier'  && (
																		<p className='mb-0'>Выкуп реальный: {item.purchase_amount} ₽</p>
																	)}
																</td>
																<td>
																	<button
																		type="button"
																		className="btn btn-outline-danger px-md-2 px-3"
																		onClick={() => confirmDelete(item.id)}
																	>
																		Удалить
																	</button>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											) : (
												<div className='row m-0'>
													<div className='col-12 text-center justify-content-center my-5'>
														<button className="btn btn-outline-warning px-5 py-4 no-hover" type="button" disabled="" >
															Заказов нету...
														</button>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</main>
		</>
	);
};