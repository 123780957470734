import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';

import { getUsers } from '../../features/AdminSlice';

export default React.memo(function UserPage() {

	const dispatch = useDispatch();
	const admin = useSelector((state) => state.admin);

	useEffect(() => {
		dispatch(getUsers());
	}, [dispatch]);

	return (
		<>
			<Sidebar />
			<main className="main-wrapper">
				<div className="main-content">
					<div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
						<div className="ps-3">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb mb-0 p-0">
									<li className="breadcrumb-item active">Статистика по пользоватилям</li>
								</ol>
							</nav>
						</div>
					</div>
					{admin.loading === true ? (
						<div className='col-12 text-center mt-5'>
							<Loader />
						</div>
					) : (
						<div className="row">
							<div className="col-12 d-flex align-items-stretch">
								<div className="card w-100 rounded-4">
									<div className="card-body">
										<div className="table-responsive">
											{admin.userData ? (
												<table className="table align-middle">
													<thead>
														<tr>
															<th style={{width: '9%'}}>ID</th>
															<th style={{width: '20%'}}>Name</th>
															<th style={{width: '20%'}}>Nickname</th>
															<th style={{width: '20%'}}>Статус</th>
															<th style={{width: '30%'}}>Статистика</th>
													</tr>
													</thead>
													<tbody>
														{admin.userData.map((item, index) => (
															<tr key={index}>
																	<td>{item.id}</td>
																	<td>{item.metadata.name}</td>
																	<td>
																			<div className="d-flex align-items-center gap-3">
																					<p className="mb-0">{item.nickname}</p>
																			</div>
																	</td>
																	<td>
																			{item.online === true ? (
																					<span className="badge bg-grd-success">Online</span>
																			) : (
																					<span className="badge bg-grd-royal">Offline</span>
																			)}
																	</td>
																	<td>
																		<Link 
																				to={`/user/${item.id}/${item.nickname}`}
																				className="btn btn-inverse-success px-md-5 px-2"
																		>
																				Посмотреть статистику
																		</Link>
																	</td>
															</tr>
													))}
													</tbody>
												</table>
											) : (
												<div className='row m-0'>
													<div className='col-12 text-center justify-content-center my-5'>
														<button className="btn btn-outline-warning px-5 py-4 no-hover" type="button" disabled="" style={{ color: '#fff' }}>
															Заведений нету...
														</button>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</main>
		</>
	);
});    