// import React, { useState, useEffect } from 'react';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';

// import { isEmpty } from 'lodash';

import Sidebar from '../../components/Sidebar';
import Maps from '../../components/Maps';
import Loader from '../../components/Loader';

// import { getCode, getToken, userLogin, starlineLogin, getCoordinates } from '../../features/MapsSlice';

export default function StartPage() {

    // const dispatch = useDispatch();
    const state = useSelector((state) => state.maps);

    // useEffect(() => {
    //     dispatch(getCode());
    // }, []);
    
    // useEffect(() => {
    //     !isEmpty(state.codeAuth) && dispatch(getToken(state.codeAuth));
    // }, [state.codeAuth]);
        
    // useEffect(() => {
    //     !isEmpty(state.tokenAuth) && dispatch(userLogin(state.tokenAuth));
    // }, [state.tokenAuth]);
        
    // useEffect(() => {
    //     !isEmpty(state.starlineUser) && !isEmpty(state.starlineUser.user_token) && dispatch(starlineLogin(state.starlineUser.user_token));
    // }, [state.starlineUser]);
            
    // useEffect(() => {
    //         !isEmpty(state.authSlid) && !isEmpty(state.cookies) && dispatch(getCoordinates({'user_id': state.authSlid.user_id, 'cookies': state.cookies}));
    // }, [state.authSlid]);
                
    // useEffect(() => {
    //     console.log('coordinates 🚗 🚗 🚗 🚗', state.coordinates);
    // }, [state.coordinates]);

    return (
        <>
            <Sidebar/>
            <main className="main-wrapper">
                {state.loading === true ? (
                    <div className='text-center mt-5'>
                        <Loader/>
                    </div>
                ) : (
                    <Maps />
                )}
            </main>
        </>
    );
};